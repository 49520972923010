import React from "react";
import profileimg from "../../src/assets/imgs/fm-logo.png";
import { AnimatedTooltip } from "../components/ui/animated-tooltip";
const people = [
  {
    id: 1,
    name: "Fernando T.G CO",
    designation: "Founder & Software Engineer",
    image:
      profileimg,
  },
  {
    id: 2,
    name: "James Morgan UK",
    designation: "Marketing Manager",
    image:
    "https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?size=338&ext=jpg&ga=GA1.1.1141335507.1718496000&semt=ais_user",
},
{
    id: 3,
    name: "Open Vacancy WD",
    designation: "Data Scientist",
    image:
    "https://img.freepik.com/free-psd/3d-illustration-person-with-glasses_23-2149436185.jpg?size=338&ext=jpg&ga=GA1.1.1141335507.1718496000&semt=ais_user",
  },
  {
    id: 4,
    name: "Open Vacancy WD",
    designation: "UX Designer",
    image:
      "https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436178.jpg",
  },
  {
    id: 5,
    name: "Open Vacancy WD",
    designation: "FrontEnd Developer",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHxYv5XjZ2Eq5wbqRdGzn3E1jxlhyNGMNipO84_irJMxkjWMtcapGYzbS8V7cQetxAycg&usqp=CAU",
  },
  {
    id: 6,
    name: "Dora CO",
    designation: "Customer Support",
    image:
      "https://images.unsplash.com/photo-1544725176-7c40e5a71c5e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3534&q=80",
  },
];

export function AnimatedTooltipPreview() {
  return (
    (<div className="flex flex-row items-center justify-center mb-10 w-full">
      <AnimatedTooltip items={people} />
    </div>)
  );
}
